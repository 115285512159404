import { HorizontalBar } from 'vue-chartjs';
import i18n from '@/i18n';
import { CHART } from '@/constants/client/chart';
import ChartDataLabels from 'chartjs-plugin-datalabels';

export default {
    name: 'BarChart',
    extends: HorizontalBar,
    props: {
        data: {
            type: Array,
            required: true
        },
        labels: {
            type: Array,
            required: true
        },
        page: {
            type: String,
            required: true
        },
        backgroundColors: {
            type: Array,
            required: true
        },
        borderColor: {
            type: String,
            required: true
        }
    },
    data() {
        return {
            datacollection: {
                labels: this.labels,
                datasets: [
                    {
                        backgroundColor: this.backgroundColors,
                        // borderColor: this.borderColor,
                        borderWidth: 1,
                        data: this.data
                    }
                ]
            },

            options: {
                plugins: {
                    datalabels: {
                        color: 'white',
                        textAlign: 'center',
                        font: {
                            weight: 'bold',
                            size: 13
                        }
                    }
                },
                legend: {
                    display: false
                },
                responsive: true,
                maintainAspectRatio: false,
                tooltips: {
                    callbacks: {
                        label: (tooltipItem, data) => {
                            let label = data.datasets[tooltipItem.datasetIndex].label || '';
                            if (label) {
                                label += ': ';
                            }

                            if (this.page === CHART.DASHBOARD.COSTS) {
                                label += i18n.n(tooltipItem.xLabel, 'currency');
                                return label;
                            } else if (this.page === CHART.DASHBOARD.INTERVENTIONS) {
                                label += tooltipItem.xLabel;
                                return label;
                            }
                        }
                    }
                }
            }
        };
    },
    mounted() {
        this.addPlugin(ChartDataLabels);
        this.renderChart(this.datacollection, this.options);
    }
};
